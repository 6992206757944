<template>
  <div class="compare-page">
    <div class="chart-wrapper">
      <div class="newUi box justify" style="margin: 15px 0">
        <div class="box">
          <!-- 时间切换 -->
          <ul class="box center">
            <li style="margin-right: 8px" class="dayColorBg pointer" v-for="(item, index) in dayList" :key="index" :class="dayActive == item.id ? 'dayColor' : ''" @click="changeDay(item.id)">
              {{ item.name }}
            </li>
          </ul>
          <div class="timeUi" style="margin-right: 8px">
            <div class="icon1 pointer" @click="handleLeft">
              <img src="../../../assets/images/alone/left.png" alt="" />
            </div>
            <div class="icon2 pointer" @click="handleRight">
              <img src="../../../assets/images/alone/right.png" alt="" />
            </div>
            <el-date-picker @change="handleSelectDate" :clearable="false" v-model="time" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy.MM.dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </div>
          <!-- 均值和原始值切换 -->
          <!-- <ul class="box center">
                    <li style="margin-right: 8px" class="dayColorBg pointer" v-for="(item, index) in timeList" :key="index"
                      :class="timeActive == item.id ? 'dayColor' : ''" @click="changeTime(item.id)">
                      {{ item.name }}
                    </li>
                  </ul> -->
        </div>
        <!-- <div class="tab_new pointer" :class="tabActive == 2 ? 'tabColor' : ''" @click="handleDownload">数据导出</div> -->
      </div>
      <div class="flex1 tu">
        <div class="box center" style="width: 100%; height: 100%">
          <div class="unitDiv" v-show="!isNoImgData">
            <div class="d1">单位：{{ units }}</div>
          </div>
          <div class="flex1 box" style="width: 100%; height: 100%; flex-direction: column" v-show="!isNoImgData">
            <div id="compareCharts"></div>
            <div style="height: 115px; margin-top: 10px; margin-bottom: 15px">
              <measure-img :imgData="imgData" :img="imgData.img" />
            </div>
          </div>
          <div class="no-data-div" v-show="isNoImgData">
            <img src="../../../assets/images/noData.png" alt="">
          </div>
        </div>
        <div class="tuBg"></div>
      </div>
    </div>
    <!-- <div class="point-list-wrapper">
            <div class="check-all-wrapper">
                <el-divider class="my-direction" direction="vertical"></el-divider>
                <span class="checked-points">已选测点</span>
            </div>
            <ul class="point-list-ul">
                <li v-for="item in sendData" :key="item.treeName">
                    <span>
                        <i class="el-icon-circle-plus-outline"></i>
                        {{item.treeName}}
                    </span>
                    <span>
                        <el-checkbox class="my-checkbox" @change="checked=>handleCheckAllChange(checked, item)"></el-checkbox>
                    </span>
                </li>
            </ul>
        </div> -->
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";
import echarts from "echarts";
import measureImg from "@/components/measureImg";

export default {
  name: "compare",
  props: ["sendData", "currSelect", "units"],
  data() {
    return {
      // 时间
      time: "",
      // 测点列表
      pointList: [],
      tabActive: "", //tab切换
      dayActive: "3", //日期选择  默认选择近10日
      timeActive: 0, //默认原始值
      win22: "0", //部分隐藏
      titleName: "展开查看全部功能",
      dayList: [
        { id: "1", name: "当天" },
        { id: "2", name: "本周" },
        { id: "3", name: "近10天" },
        { id: "4", name: "本月" }
      ],
      timeList: [
        { id: "0", name: "原始值(近1小时)" },
        { id: "1", name: "1分钟均值" },
        { id: "2", name: "10分钟均值" },
        { id: "3", name: "1小时均值" },
        { id: "4", name: "日均值" }
      ],
      size: "50000", //图表最多显示多少条
      time: "",
      time1: "",
      time2: "",
      arrData: [], // 曲线总条数
      currentPoint: {}, // 当前点击的测点数据,
      originData: [], // 原始值曲线总条数
      nowData: [], // 却换不同状态曲线总条数
      titleList: [], // 测点名称  截面号 位置
      imgData: { img: "", list: [] },
      multiNowData: [],
      isNoImgData: true,
      showFlag: true,
      defineArr: [],
      emptyArr: []
    };
  },

  watch: {
    sendData(newValue, oldValue) {
      this.nowData = [];

      this.imgData["img"] = "";
      this.imgData["list"] = [];
      this.multiNowData = [];
      // this.pointList = newValue
      console.log("接收到的测点列表", newValue);

      if (newValue.length > 0) {
        this.getImgDataList();
        this.getExistedChartData();
      } else {
        this.isNoImgData = true
        this.$emit("getMultiImgData", []);
        this.defineArr = []
        this.emptyArr = []
        document.getElementById("compareCharts").removeAttribute("_echarts_instance_")
        // this.$chart1.moreLine2("compareCharts", [], [], "", "");
      }
    }
    // currSelect(newValue, oldValue) {
    //   console.log('接收到的当前点击的测点----------------------', newValue)
    // }
  },
  mounted() {
    // this.pointList = this.sendData

    var day = new Date();
    var day1 = day.getTime();
    var day2 = day1 - 10 * 24 * 60 * 60 * 1000;

    let time2 = day.getTime();
    let time1 = day1 - 10 * 24 * 60 * 60 * 1000;

    var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
    var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
    // console.log(start, end)
    this.time = [];
    this.time.push(start);
    this.time.push(end);
  },
  created() {
    this.isNoImgData = true
    // this.$chart1.moreLine2("compareCharts", [], [], "")
    // this.arrData = []
    // this.getChartData()
  },
  methods: {
    // 获取图片数据  截面号  位置等信息
    getImg(id) {
      return `${this.baseURL}sys/sysImages/img/${id}`;
    },
    getImgData(id) {
      this.$axios
        .get(`${this.baseURL}stress/target/info/${id}`)
        .then((res) => {
          console.log("图数据", res);
          if (res.data && res.data.errCode == "200") {
            let data = res.data.data || {};

            if (data.imageId) {
              if (!this.imgData.img) {
                this.imgData["img"] = this.getImg(data.imageId);
              }

              let reg = {
                back: data.back,
                backName: data.backName,
                front: data.front,
                frontName: data.frontName,
                profileName: data.profileName
              };

              this.imgData["list"].push(reg);
              this.multiNowData.push(data);
            }

            console.log("this.multiNowData.length", this.multiNowData.length, "his.sendData.length", this.sendData.length);

            if (this.multiNowData.length === this.sendData.length) {
              this.$emit("getMultiImgData", this.multiNowData);
            }
          }
        })
        .catch((err) => {});
    },

    getImgDataList() {
      this.multiNowData.splice(0);
      if (this.sendData.length == 0) {
        this.nowData = [];

        this.imgData["img"] = "";
        this.imgData["list"] = [];
        this.multiNowData = [];
        document.getElementById("compareCharts").removeAttribute("_echarts_instance_")
        // this.$chart1.moreLine2("compareCharts", [], [], "", "");
        this.isNoImgData = true
        this.$emit("getMultiImgData", []);
      }
      console.log("this.sendData", this.sendData, "this.multiNowData", this.multiNowData);
      this.sendData.forEach((item) => {
        this.getImgData(item.treeTargetId);
      });
    },

    // YYYY-MM-DD hh:mm:ss'转时间戳
    formDate(time) {
      const thisTime = time.replace(/ /g, "/");
      var nowTime = new Date(thisTime);
      nowTime = nowTime.getTime();
      return nowTime;
    },

    // 获取均值数据
    getChartData(item) {
      let path = `data/history/raw/average/${item.treeTargetId}?interval=${this.timeActive}&sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios.get(`${this.baseURL}${path}`).then((res) => {
        console.log("获取均值数据-----------------------------", res);
        let detail = res.data.data;
        let time = [];
        let average = [];
        detail.data.forEach((eve) => {
          time.push(eve.time);
          average.push(eve.data[2]);
        });
        let templates = {
          name: item.treeName + "均值",
          type: "line",
          smooth: true,
          data: average,
          symbol: "circle",
          symbolSize: 0,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#1E315C"
                },
                {
                  offset: 0.5,
                  color: "rgba(33,50,96, 0.6)"
                },
                {
                  offset: 0.9,
                  color: "rgba(33,50,96, 0.1)"
                }
              ])
            }
          }
        };
        this.arrData.push(templates);
        this.$chart1.moreLine2("compareCharts", time, this.arrData, "", "");
      });
    },

    // 获取原始值
    getOriginData() {
      if (this.sendData && this.sendData.length > 0) {
        this.sendData.forEach((item) => {
          let path = `stress/raw/${item.treeTargetId}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}&reverse=1`;
          this.$axios.get(`${this.baseURL}${path}`).then((res) => {
            console.log("获取原始值数据-----------------------------", res);
            let detail = res.data.data;
            let time = [];
            let values = [];
            detail.forEach((eve) => {
              time.push(eve.time);
              values.push(eve.data[0]);
            });
            let templates = {
              name: item.treeName,
              type: "line",
              smooth: true,
              data: values,
              symbol: "circle",
              symbolSize: 0,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#1E315C"
                    },
                    {
                      offset: 0.5,
                      color: "rgba(33,50,96, 0.6)"
                    },
                    {
                      offset: 0.9,
                      color: "rgba(33,50,96, 0.1)"
                    }
                  ])
                }
              }
            };
            this.originData.push(templates);
            this.$chart1.moreLine2("compareCharts", time, this.originData, "", "");
          });
        });
      }
    },

    // 获取现有测点数据 不同状态下
    getExistedChartData() {
      console.log('获取图表数据之前查看当前选中的测点--------', this.sendData)
      this.nowData = [];
      let arr = []
      if (this.sendData && this.sendData.length > 0) {
        this.defineArr = []
        // if (this.sendData.length == 1) {
        //   this.defineArr = []
        // }
        this.sendData.forEach((item) => {
          let path = `stress/raw/${item.treeTargetId}?sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
          this.$axios.get(`${this.baseURL}${path}`).then((res) => {
            console.log("获取原始值数据111111111111111111-----------------------------", res);
            let detail = res.data.data;
            let seriesData = []
            let time = []
            if (detail.length > 0) {
              if (this.defineArr.indexOf(item.treeName) < 0) {
                this.defineArr.push(item.treeName)
              }  

              
            detail.forEach((eve) => {
              time.push(eve.time)
              seriesData.push([eve.time, eve.data[0]]);
            });
            let templates = {
              name: item.treeName,
              type: "line",
              smooth: true,
              data: seriesData,
              symbol: "circle",
              symbolSize: 0,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#1E315C"
                    },
                    {
                      offset: 0.5,
                      color: "rgba(33,50,96, 0.6)"
                    },
                    {
                      offset: 0.9,
                      color: "rgba(33,50,96, 0.1)"
                    }
                  ])
                }
              }
            };
            this.nowData.push(templates);

            } else {
              if (this.emptyArr.indexOf(item.treeName) < 0) {
                this.emptyArr.push(item.treeName)
              }
            }
            arr.push(1)
            
            console.log("this.defineArr", this.defineArr, "this.emptyArr", this.emptyArr);
            console.log('渲染数据', this.nowData)

            // console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeee--------------', arr.length, this.sendData.length)
            
            if (arr.length == this.sendData.length) {
              // 执行完
              if (this.defineArr.length > 0) {
                this.isNoImgData = false
                document.getElementById("compareCharts").removeAttribute("_echarts_instance_")
            document.getElementById("compareCharts").setAttribute("_echarts_instance_", "")
                console.log('渲染数据111', this.nowData)
                setTimeout(() => {
                  this.$chart1.moreLine2("compareCharts", time, this.nowData, "")
                }, 500)
              } else {
                this.isNoImgData = true
              }
            }

          });

          

        });
        // setTimeout(() => {
        //   this.$chart1.moreLine2("compareCharts", this.nowData, "");
        // }, 100);
      }
    },
    // 时间切换
    handleSelectDate() {
      this.getExistedChartData();
    },
    // 判断是原始值还是均值
    selectData() {
      // 原始值
      this.originData = [];
      this.getOriginData();
      // if (this.timeActive == 0) {
      //     // 原始值
      //     this.originData = []
      //     this.getOriginData()
      // } else {
      //     // 均值
      //     this.nowData = []
      //     this.getExistedChartData()
      // }
    },
    // 向左
    handleLeft() {
      let end = this.time[1];
      let start = this.formDate(this.time[0]);
      let date = start - 24 * 60 * 60 * 1000;
      let time = moment(date).format("YYYY-MM-DD HH:mm:ss");
      this.time = [time, end];
      this.dayActive = 0;
      this.selectData();
    },
    // 向右
    handleRight() {
      let start = this.time[0];
      let end = this.formDate(this.time[1]);
      let date = end + 24 * 60 * 60 * 1000;
      let time = moment(date).format("YYYY-MM-DD HH:mm:ss");
      this.time = [start, time];
      this.dayActive = 0;
      this.selectData();
    },
    // 测点复选框
    // handleCheckAllChange(checked, item) {
    //     console.log('复选框选中状态--------', checked, '当前选中的item', item)
    //     if (checked) {
    //         this.currentPoint = item
    //         this.getChartData(item.treeTargetId)
    //     }
    // },
    changeTime(timeId) {
      this.timeActive = timeId; //均值的时间id
      this.selectData();
    },

    // 测点复选框
    // handleCheckAllChange(checked, item) {
    //     console.log('复选框选中状态--------', checked, '当前选中的item', item)
    //     if (checked) {
    //         this.currentPoint = item
    //         this.getChartData(item.treeTargetId)
    //     }
    // },

    // 时间切换
    changeDay(id) {
      console.log("快捷选天", id);
      var day = new Date();

      var time2 = day.getTime(); //当前时间戳
      this.time2 = time2; //保存当前时间时间戳
      time2 = moment(time2).format("YYYY-MM-DD HH:mm:ss");
      console.log("time2", time2);

      // 获取当天
      if (id == 1) {
        var time1 = new Date(new Date().toLocaleDateString()).getTime(); //当天时间戳
        this.time1 = time1; //保存开始处时间错
        time1 = moment(time1).format("YYYY-MM-DD HH:mm:ss");
        console.log("time1", time1);
        this.time = [time1, time2];
      }
      // 本周
      if (id == 2) {
        var nowTemp = new Date(); //当前时间
        var oneDayLong = 246060 * 1000; //一天的毫秒数
        var c_time = nowTemp.getTime(); //当前时间的毫秒时间
        var c_day = nowTemp.getDay() || 7; //当前时间的星期几
        var m_time = c_time - (c_day - 1) * oneDayLong; //当前周一的毫秒时间
        var monday = new Date(m_time); //设置周一时间对象
        var m_year = monday.getFullYear();
        var m_month = monday.getMonth() + 1;
        var m_date = monday.getDate();
        var time = m_year + "-" + m_month + "-" + m_date + " " + "00:00:00"; //周一的年月日
        console.log("time", time);
        this.time = [time, time2];
        this.time1 = monday; //获取周一0点时间戳
      }
      // 近十天
      if (id == 3) {
        var day = new Date();
        var day1 = day.getTime(); //获得当前时间戳
        var day2 = day1 - 10 * 24 * 60 * 60 * 1000;
        this.time1 = day2;
        // this.time1 = day.getTime();                   //当前时间戳
        // this.time2 = day1 - 10*24 * 60 * 60 * 1000;  //中国标准时间
        var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
        var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
        this.time = [start, end];
      }
      // 本月
      if (id == 4) {
        var data = new Date();
        data.setDate(1);
        data.setHours(0);
        data.setSeconds(0);
        data.setMinutes(0);
        var time = this.formatDateTime(data.getTime());
        this.time = [time, time2];
      }

      this.dayActive = id;

      this.selectData(); //判断是原始值，还是均值
    },
    formatDateTime(inputTime) {
      var date = new Date(inputTime);
      this.time1 = date;
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    win2Title() {
      if (this.win22 == 1) {
        this.titleName = "展开查看全部功能";
        this.win22 = 0;
      } else {
        this.titleName = "收起查看全部功能";
        this.win22 = 1;
      }
    }
  },
  components: { measureImg }
};
</script>

<style scoped lang="scss">
.compare-page {
  width: 100%;
  height: calc(100% - 66px);
  display: flex;
  .chart-wrapper {
    width: 100%;
    // margin-right: 30px;
    // padding: 0 15px;
    // background: #04153f;
    .time-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      text-align: center;
      .select-css {
        height: 32px;
        line-height: 32px;
        ::v-deep input {
          border: 1px solid #2667db;
        }
      }
    }
  }
  .point-list-wrapper {
    flex: 1;
    background: #04153f;
    padding: 15px;
    .check-all-wrapper {
      margin-bottom: 10px;
      .my-direction {
        width: 2px;
        height: 7px;
        background: #2667db;
      }
      .checked-points {
        width: 90%;
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(69, 202, 255, 0.21);
      }
    }
    .point-list-ul {
      line-height: 3;
      font-size: 12px;
      font-weight: 400;
      padding: 0 30px;
      li {
        display: flex;
        justify-content: space-between;
        ::v-deep .my-checkbox span.el-checkbox__input .el-checkbox__inner {
          background: #10367e;
          border: 1px solid #10367e;
        }
      }
    }
  }
}
.timeUi {
  position: relative;

  .icon1 {
    position: absolute;
    top: 12px;
    left: 6px;
    z-index: 99;
  }

  .icon2 {
    position: absolute;
    top: 12px;
    right: 6px;
    z-index: 99;
  }

  .el-range-editor.el-input__inner {
    width: 264px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #233361 !important;
    background: #04153f;
    border-radius: 16px !important;
  }

  .el-range-input {
    width: 112px;
    letter-spacing: 0px;
    font-size: 12px !important;
    background: transparent !important;
  }

  /* // 至 */
  .el-date-editor .el-range-input,
  .el-date-editor .el-range-separator {
    color: #ffffff;
    height: 30px !important;
  }

  /* // 头部图标 */
  .el-date-editor .el-range__icon {
    display: none !important;
  }
}
.dataShow {
  width: 100%;
  // height: 100%;
}

// 默认tab切换
.tab_new {
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  width: 96px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #2667db;
}

// 选中tab
.tabColor {
  font-size: 14px;
  width: 96px;
  height: 32px;
  background: #2667db;
  border-radius: 16px;
  border: 1px solid #233361;
}

.dayColorBg {
  // width: 80px;
  padding-left: 22px;
  padding-right: 22px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #7387c6;
  background: #233361;
  border-radius: 15px;
  letter-spacing: 0px;
}

.dayColor {
  color: #cedaff !important;
  background: #0050b4 !important;
}

::v-deep .newUi {
  .el-select {
    width: 118px;
    height: 32px !important;

    .el-input {
      height: 32px !important;

      .el-input__inner {
        border: 1px solid #233361 !important;
        border-radius: 16px !important;
      }

      .el-input__icon {
        line-height: 17px;
      }
    }
  }

  .timeUi {
    position: relative;

    .icon1 {
      position: absolute;
      top: 12px;
      left: 6px;
      z-index: 99;
    }

    .icon2 {
      position: absolute;
      top: 12px;
      right: 6px;
      z-index: 99;
    }

    .el-range-editor.el-input__inner {
      width: 264px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #233361 !important;
      background: #04153f;
      border-radius: 16px !important;
    }

    .el-range-input {
      width: 112px;
      letter-spacing: 0px;
      font-size: 12px !important;
      background: transparent !important;
    }

    // 至
    .el-date-editor .el-range-input,
    .el-date-editor .el-range-separator {
      color: #ffffff;
      height: 30px !important;
    }

    // 头部图标
    .el-date-editor .el-range__icon {
      display: none !important;
    }
  }
}

::v-deep .new_ui_select {
  .el-select {
    width: 200px;
    height: 32px !important;

    .el-input {
      height: 32px !important;

      .el-input__inner {
        border: 1px solid #233361 !important;
        border-radius: 16px !important;
      }

      .el-input__icon {
        line-height: 17px;
      }
    }
  }
}

// 图表样式
.tu {
  width: 100%;
  height: calc(100% - 120px);
  position: relative;
  padding-top: 17px;
  padding-left: 15px;
  padding-right: 15px;
}

.tuBg {
  position: absolute;
  background: rgba($color: #fff, $alpha: 0.05);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.legend1 {
  width: 24px;
  height: 3px;
  background: #2772f0;
  border-radius: 2px;
}

.legend2 {
  width: 24px;
  height: 3px;
  background: #f7b500;
  border-radius: 2px;
}

.legend3 {
  width: 24px;
  height: 3px;
  background: #80c0ff;
  border-radius: 2px;
}

#myecharts {
  width: 100%;
  height: 320px;
  z-index: 2;
  overflow: hidden;
}

.close {
  position: absolute;
  right: 0;
  top: -30px;
  right: 0px;
}

.a {
  z-index: 99;
}

.b {
  z-index: -1;
}

//单位竖
.unitDiv {
  width: 22px;

  // position: relative;
  .d1 {
    transform: rotate(-90deg) translate(-20%, -50%);
    transform-origin: 0% 0%;
    position: absolute;
    left: 40px;
    // top: 50%;
    // left: 0;
    width: 150px;
  }
}

.width50 {
  width: 50px;
}

.width60 {
  width: 100px;
}

.bottom {
  overflow-y: auto;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.no-data-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 74px;
    height: 66px;
  }
}
#compareCharts {
  // width: 100%;
  // height: 100%;
  flex: 1;
  z-index: 2;
  overflow: hidden;
}
</style>
