<template>
  <div class="stress">
    <left-link-right>
      <!-- 左侧栏 -->
      <div class="left-tree" slot="leftLayout">
        <div class="search-input">
          <el-input v-model="keywords" placeholder="请输入关键字" />
          <i class="el-icon-search" @click="handleSearchIconClick"></i>
        </div>
        <ul class="left-ul">
          <div class="ul-div" v-for="item in firstTwoViewList" :key="item.id">
            <li @click="handleTabClick(item)" :class="currentViewId == item.id ? 'active-li' : ''">
              <span>{{ item.viewName }}</span>
            </li>
            <el-divider class="my-divider" direction="vertical"></el-divider>
          </div>
          <!-- <li style="width: 35%" @click="handleCustomClick">
            <span><i class="el-icon-circle-plus-outline add"></i>自定义</span>
          </li>
          <el-divider class="my-divider" direction="vertical"></el-divider>
          <li style="width: 15%" @click="handleResetClick">
            <span><i class="el-icon-refresh-left reset"></i></span>
          </li> -->
        </ul>
        <div class="tree-wrapper">
          <el-tree
            class="my-tree"
            :data="viewTreeData"
            :show-checkbox="isShowCheckbox"
            :default-expand-all="false"
            node-key="id"
            ref="viewTree"
            :default-checked-keys="defaultKeys"
            :default-expanded-keys="defaultExpandKeys"
            :filter-node-method="filterNode"
            highlight-current
            @node-click="handleNodeClick"
            @check-change="handleCheckChange"
            @check="handleCheck"
            :props="defaultProps"
          >
          </el-tree>
        </div>
      </div>
      <!-- 右侧区域 -->
      <div class="right-info" slot="rightLayout">
        <div class="top-wrapper">
          <div class="point-name" v-if="isShowSinglePage">
            <img src="@/assets/images/stress/point.png" alt="" />
            <span>{{ pointName }}</span>
            <span class="middle-span" v-if="imgData.profileName">截面号：{{ imgData.profileName }}</span>
            <span v-if="imgData.siteName">位置：{{ imgData.siteName }}</span>

            <!-- <span class="point-name-title">{{ pointName }}</span>
            <span class="point-name-code" v-if="imgData.profileName">截面号：{{ imgData.profileName }}</span>
            <span class="point-name-pos" v-if="imgData.siteName">位置：{{ imgData.siteName }}</span>
         -->
          </div>
          <!-- <ul v-else class="point-ul"> -->
          <ul v-else class="point-name point-ul">
            <!-- <li v-for="item in sendData" :key="item.id">
              <img src="@/assets/images/stress/point.png" alt="" />
              <span>{{ item.treeName }}</span>
            </li> -->

            <li v-for="item in multiImgData" :key="item.id">
              <img src="@/assets/images/stress/point.png" alt="" />
              <span>{{ item.code }}</span>
              <span class="middle-span" v-if="item.profileName">截面号：{{ item.profileName }}</span>
              <span v-if="item.siteName">位置：{{ item.siteName }}</span>

              <!-- <span class="point-name-title">{{ item.code }}</span>
              <span class="point-name-code" v-if="item.profileName">截面号：{{ item.profileName }}</span>
              <span class="point-name-pos" v-if="item.siteName">位置：{{ item.siteName }}</span>
              <span class="point-name-pos" v-if="item.siteName">位置：{{ item.siteName }}</span> -->
            </li>
          </ul>
          <div class="btn-right">
            <el-button @click="handleCompareBtn" class="compare-btn" :class="!myOption ? 'disable_icon' : ''">
              <span v-if="isShowSinglePage">多测点对比分析</span>
              <span v-else>返回单侧点</span>
            </el-button>
            <!-- <el-button @click="handleDownloadBtn">导出</el-button> -->
          </div>
        </div>
        <!-- 单测点页面 -->
        <div v-if="isShowSinglePage" class="single-point-page">
          <div class="chart-wrapper">
            <charts :getData="getData" :targetId="pointId" :units="units" @getImgData="getImgData"></charts>
          </div>
        </div>
        <!-- 多测点对比分析页面 -->
        <compare-point v-else :sendData="sendData" :units="units" @getMultiImgData="getMultiImgData"></compare-point>
      </div>
    </left-link-right>
    <!-- 自定义弹窗 -->
    <el-dialog :visible.sync="dialogVisible" class="my-dialog" top="10vh" :before-close="handleDialogClose">
      <div class="add-wrapper">
        <div class="add-name">
          <span>视图名称</span>
          <el-input type="text" v-model.trim="viewName" placeholder="请输入视图名称" @keyup.enter.native="handleAddView"></el-input>
        </div>
        <div class="list-wrapper">
          <img @click="handleListBtnClick" src="@/assets/images/stress/list.png" alt="" />
          <ul v-show="isShowList" class="select-ul">
            <li v-for="(item, index) in viewList" :key="item.id" @click="handleViewClick(item)" @mouseover="handleMouseEnter(item)" @mouseleave="handleMouseLeave(item)">
              <span class="name">
                <span v-if="!item.showInput">{{ item.viewName }}</span>
                <span v-else>
                  <el-input class="my-edit-input" v-model.trim="inputValue" @keyup.enter.native="handleEnterEvent(item)" onkeyup="this.value=this.value.replace(/[, ]/g,'')"> </el-input>
                </span>
              </span>
              <span class="icon" v-show="item.flag">
                <el-tooltip class="item" effect="dark" content="上移" placement="bottom">
                  <i class="el-icon-s-fold" v-show="index > 0" @click.stop="handleGoUp(item, index)"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="下移" placement="bottom">
                  <i class="el-icon-s-unfold" v-show="index < viewList.length - 1" @click.stop="handleGoDown(item, index)"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="编辑" placement="bottom">
                  <i class="el-icon-edit-outline" @click.stop="handleEditIcon(item, index)"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                  <i class="el-icon-delete" @click.stop="handleDeleteIcon(item, index)"></i>
                </el-tooltip>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="btn-wrapper">
        <el-tooltip class="item" effect="dark" content="增加节点" placement="bottom">
          <img src="@/assets/images/stress/add_node.png" alt="" @click="handleAddTop" />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="升级节点" placement="bottom">
          <img src="@/assets/images/stress/left.png" alt="" @click="handleUpgrade" />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="降级节点" placement="bottom">
          <img src="@/assets/images/stress/right.png" alt="" @click="handleDownGrade" />
        </el-tooltip>
      </div>
      <div class="tree-wrapper">
        <div class="dialog-left-tree">
          <el-tree
            class="my-left-tree"
            :data="leftData"
            show-checkbox
            :default-expand-all="false"
            node-key="id"
            ref="leftTree"
            :default-checked-keys="defaultLeftKeys"
            :default-expanded-keys="defaultLeftExpandKeys"
            highlight-current
            @node-click="handleLeftNodeClick"
            @check="handleLeftCheckChange"
            :props="defaultLeftProps"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span v-show="!data.editFlag" class="node-name">{{ data.treeName }}</span>
              <span class="node-icon" v-show="data.flag">
                <el-tooltip class="item" effect="dark" content="增加子节点" placement="bottom">
                  <i class="el-icon-circle-plus" @click="handleViewTreeAdd(node, data)"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="编辑节点名称" placement="bottom">
                  <i class="el-icon-edit-outline" @click.stop="handleViewTreeEdit(node, data)"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除节点" placement="bottom">
                  <i class="el-icon-delete" @click="handleViewTreeDelete(node, data)"></i>
                </el-tooltip>
              </span>
              <span v-show="data.editFlag">
                <el-input class="my-edit-input" :value="editInputValue" onkeyup="this.value=this.value.replace(/[, ]/g,'')" @keyup.enter.native="handleViewEditSubmit(node, data)" @input="(a) => handleInputEvent(a, node, data)"> </el-input>
              </span>
            </span>
          </el-tree>
        </div>
        <div class="dialog-btn">
          <div class="dialog-btn-group">
            <el-button @click="handleBtnFromLeft">从左边移动到组</el-button>
            <el-button @click="handleBtnFromRight">从右边撤销归组</el-button>
          </div>
        </div>
        <div class="dialog-right-tree">
          <div class="search-input">
            <el-input v-model="rightKeywords" @input="handleRightKeyWordsInput" placeholder="请输入关键字" />
            <i class="el-icon-search" @click="handleRightFilter"></i>
          </div>
          <el-tree class="my-right-tree" :data="rightData" show-checkbox :default-expand-all="false" node-key="targetId" ref="rightTree" :default-expanded-keys="defaultRightKeys" highlight-current :props="defaultRightProps">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ data.targetName }}</span>
              <span v-if="data.children.length > 0">({{ data.children.length }})</span>
            </span>
          </el-tree>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LeftLinkRight from "@/components/LeftLinkRight";
import Charts from "./components/Chart";
import TableList from "./components/TableList";
import ComparePoint from "./components/Compare";
import qs from "qs";
export default {
  name: "stress",
  data() {
    return {
      // 项目id
      projectId: sessionStorage.getItem("projectId"),
      dialogVisible: false,
      isShowList: false, // 是否显示视图列表
      viewName: "", // 视图名称
      keywords: "", // 关键字搜索
      rightKeywords: "", // 自定义右侧树关键字搜索
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      isShowSinglePage: true, // 默认显示单测点页面
      // isInit: '',
      pointName: "", // 测点名称
      // 视图tree
      viewTreeData: [],
      // 默认选中的节点
      defaultKeys: [],
      // 默认展开的节点
      defaultExpandKeys: [],
      defaultProps: {
        children: "children",
        label: "treeName"
      },
      // 是否点击编辑
      isEdit: false,
      // 编辑的内容
      inputValue: "",
      // 视图下拉数据
      viewList: [],
      // 自定义 左树
      leftData: [],
      // 自定义 右树
      rightData: [],
      // 前两个视图
      firstTwoViewList: [],
      // 视图树 当前点击的视图
      currentViewId: "",
      // 自定义 当前点击的视图
      diyCurrViewId: "",
      defaultLeftKeys: [],
      defaultLeftExpandKeys: [],
      defaultLeftProps: {
        children: "children",
        label: "treeName"
      },
      defaultRightKeys: [],
      defaultRightProps: {
        children: "children",
        label: "targetName"
      },
      // 自定义 左侧视图结构树当前选中的节点
      diyLeftCurrentSelect: {},
      // 视图列表id
      viewListIds: [],
      // 仅当前点击的节点可以增改删
      dblClickNodeArr: [],
      // 视图树编辑
      editInputValue: "",
      // 测点id
      pointId: "",
      // 传给图表的数据
      getData: [],
      units: "",
      // 传给多测点对比分析页面的数据
      sendData: [],
      // 当前选中的测点
      // currSelect: {},
      // 是否显示checkbox
      isShowCheckbox: false,
      currentEditNode: {},
      isHandleEdit: false,
      getExpandId: "",
      imgData: {},
      multiImgData: []
    };
  },
  watch: {
    keywords(val) {
      this.$refs.viewTreeData.filter(val);
    }
    // 自定义 右侧树
    // rightKeywords(val) {
    //   this.$refs.rightTree.filter(val);
    // }
  },
  created() {
    this.getViewsList();
  },
  mounted() {
    // setTimeout(() => {
    //   this.getViewsTree(this.currentViewId);
    // }, 100);
  },
  methods: {
    getMultiImgData(data) {
      console.log("sss", data);
      this.multiImgData = data;
    },

    getImgData(data) {
      console.log("传过来的位置信息", data);
      this.imgData = data;
    },
    // 获取视图列表
    getViewsList() {
      this.$axios.get(`${this.baseURL}stress/view/${this.projectId}`).then((res) => {
        this.viewListIds = [];
        console.log("获取视图列表数据", res);
        let detail = JSON.parse(JSON.stringify(res.data.data));
        detail.forEach((item) => {
          item.flag = false;
          this.viewListIds.push(item.id);
        });
        this.viewList = detail;
        let list = JSON.parse(JSON.stringify(res.data.data));
        this.firstTwoViewList = list.slice(0, 2);
        this.currentViewId = detail[0].id;
        this.getViewsTree(this.currentViewId);
      });
    },
    // 获取视图树展开的节点
    getTreeExpandId(list) {
      if (list[0].children && list[0].children.length > 0) {
        this.getTreeExpandId(list[0].children);
      } else {
        // console.log('展开展开展开展开展开---------------------', list[0])
        // 默认展开第一个节点的末级
        this.defaultExpandKeys = [list[0].id];
        // 默认高亮第一个节点
        this.$refs.viewTree.setCurrentKey(list[0].id);
        // 右侧默认显示第一个展开的测点的数据图表
        this.getData = [list[0]];
        this.pointId = list[0].treeTargetId;
        // 获取单位
        this.getPoint(list[0]);
        // 赋值测点名称
        this.pointName = list[0].treeName;
      }
    },
    // 获取视图结构树
    getViewsTree(id) {
      this.$axios.get(`${this.baseURL}stress/view/tree/${id}?name=${this.keywords}`).then((res) => {
        console.log("获取视图tree数据", res);
        this.viewTreeData = res.data.data;
        // 展开第一个节点的最末级
        if (res.data.data) {
          setTimeout(() => {
            this.getTreeExpandId(res.data.data);
          }, 100);
        }
      });
    },
    // 搜索关键字 左侧视图结构树
    handleSearchIconClick() {
      this.getViewsTree(this.currentViewId);
    },
    // tab点击事件
    handleTabClick(item) {
      this.currentViewId = item.id;
      this.getViewsTree(item.id);
    },
    // 过滤节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getPoint(data) {
      this.$axios.get(`${this.baseURL}base/baseTarget/${data.treeTargetId}`).then((res) => {
        console.log("获取测点数据单位值-----", res);
        // this.getData[0].unit = res.data.data.unit
        this.units = res.data.data.unit;
      });
    },
    // 点击树节点
    handleNodeClick(data) {
      // this.isShowSinglePage = true
      console.log("点击视图树节点", data);
      if (!data.children.length) {
        this.pointName = data.treeName;
        this.pointId = data.treeTargetId;
        this.getData = [];
        this.getData.push(data);
        // 获取测点数据
        this.getPoint(data);
      }
    },
    // 判断是否是同类别的最多8个测点
    addSelectNode(data) {
      if (this.sendData.length == 0) {
        this.sendData.push(data);
      } else if (this.sendData.length > 0 && this.sendData.length < 8) {
        if (data.treeTopParentId == this.sendData[0].treeTopParentId) {
          this.sendData.push(data);
        } else {
          this.sendData = [];
          this.sendData.push(data);
          this.$refs.viewTree.setCheckedNodes([]);
          this.$refs.viewTree.setCheckedNodes(this.sendData);
        }
      } else {
        this.$message.warning("最多选择同类别8个测点");
        // this.currSelect = {}
      }
    },
    // 取消选中的测点
    removeSelectNode(data) {
      this.sendData.forEach((item, index) => {
        if (item.id == data.id) {
          this.sendData.splice(index, 1);
        }
      });
    },

    handleCheck(data, node) {
      console.log("点击左树", data, node);
      let res = this.$refs["viewTree"].getCheckedNodes(true);
      console.log("res", res);
      if (this.sendData.length && this.sendData[0].treeTopParentId != data.treeTopParentId) {
        this.$message.warning("请选择同类别测点");
        this.$refs["viewTree"].setChecked(data, false, true);
        return;
      }
      if (res.length > 8) {
        this.$message.warning("最多选择同类别8个测点");
        this.$refs["viewTree"].setChecked(data, false, true);
        return;
      }
      this.sendData = res;
    },

    // 树checkbox选中状态
    handleCheckChange(data, checked, indeterminate) {
      console.log("视图结构树选中的节点", data, checked);
      // if (checked && data.children.length == 0) {
      //   this.addSelectNode(data);
      //   // this.currSelect = data
      //   this.getPoint(data);
      // }
      // if (!checked && data.children.length == 0) {
      //   this.removeSelectNode(data);
      // }
    },
    // 多测点对比分析
    handleCompareBtn() {
      if (!this.myOption) {
        this.$message.error("您没有操作权限！");
        return;
      }
      this.isShowSinglePage = !this.isShowSinglePage;
      this.isShowCheckbox = !this.isShowCheckbox;
      this.$refs.viewTree.setCheckedNodes([]);
    },
    // 获取默认结构树
    getDefaultTree(id) {
      this.$axios.get(`${this.baseURL}stress/target/${this.projectId}?viewId=${id}&name=${this.rightKeywords}`).then((res) => {
        console.log("获取自定义右侧默认结构树", res);
        this.rightData = res.data.data;
        this.defaultRightKeys = [res.data.data[0].targetId];
      });
    },
    // 自定义
    handleCustomClick() {
      this.dialogVisible = true;
      console.log("当前视图id", this.currentViewId);
      this.getDefaultTree(0);
    },
    // 关闭自定义弹窗
    handleDialogClose() {
      this.dialogVisible = false;
      this.isShowList = false;
      this.viewName = "";
      this.diyCurrViewId = "";
      this.leftData = [];
      this.rightKeywords = "";
    },
    // 重置
    handleResetClick() {
      this.isShowSinglePage = true;
      this.isShowCheckbox = false;
      this.$refs.viewTree.setCheckedNodes([]);
      this.sendData = [];
    },
    // 新增视图 回车事件
    handleAddView() {
      this.$axios.post(`${this.baseURL}stress/check/view/name?projectId=${this.projectId}&viewName=${this.viewName}`).then((res) => {
        console.log("检查新增视图名称是否重复", res);
        if (res.data.status == 0) {
          this.$message.warning(res.data.errMsg);
          return;
        } else {
          let data = {
            projectId: this.projectId,
            viewName: this.viewName
          };
          this.$axios.post(`${this.baseURL}stress/add/view`, data).then((res) => {
            console.log("新增视图", res);
            if (res.data.status == 200) {
              this.$message.success("新增视图成功");
              this.getViewsList();
              this.diyCurrViewId = res.data.data;
              this.leftData = [];
            }
          });
        }
      });
    },
    // 点击选择视图
    handleListBtnClick() {
      this.isShowList = !this.isShowList;
    },
    // 树节点循环增加flag字段，用于显示增改删；增加editFlag字段，用于显示输入框
    addTreeField(list) {
      list.forEach((item) => {
        item.flag = false;
        item.editFlag = false;
        if (item.children && item.children.length) {
          this.addTreeField(item.children);
        }
      });
    },
    // 获取自定义视图树（左侧）
    getDiyTree(id) {
      this.$axios.get(`${this.baseURL}stress/view/tree/${id}`).then((res) => {
        let detail = JSON.parse(JSON.stringify(res.data.data));
        this.addTreeField(detail);
        this.leftData = detail;
      });
    },
    // 点击视图
    handleViewClick(item) {
      if (this.isEdit) {
        return;
      }
      this.viewName = item.viewName;
      this.diyCurrViewId = item.id;
      this.isShowList = false;
      this.getDiyTree(item.id);
      this.getDefaultTree(item.id);
      this.dblClickNodeArr = [];
    },
    // 鼠标移入视图列表
    handleMouseEnter(item) {
      item.flag = !this.isEdit;
    },
    // 鼠标移出视图列表
    handleMouseLeave(item) {
      item.flag = false;
      item.showInput = false;
      this.isEdit = false;
    },
    // 上下移通用函数
    sortViewList(ids) {
      console.log(ids);
      this.$axios.post(`${this.baseURL}stress/sortView`, ids).then((res) => {
        if (res.status == 200) {
          this.getViewsList();
        }
      });
    },
    // 视图列表 上移
    handleGoUp(item, index) {
      console.log("上移", item, "index", index);
      this.viewListIds.splice(index, 1);
      this.viewListIds.splice(index - 1, 0, item.id);
      console.log(this.viewListIds);
      this.sortViewList(this.viewListIds);
      this.getViewsTree(this.viewListIds[0]);
    },
    // 视图列表 下移
    handleGoDown(item, index) {
      console.log("下移", item, "index", index);
      this.viewListIds.splice(index, 1);
      this.viewListIds.splice(index + 1, 0, item.id);
      console.log(this.viewListIds);
      this.sortViewList(this.viewListIds);
      this.getViewsTree(this.viewListIds[0]);
    },
    // 视图列表 编辑
    handleEditIcon(item, index) {
      this.isEdit = true;
      console.log("编辑", item, "index", index);
      item.showInput = true;
      item.flag = false;
      this.inputValue = item.viewName;
    },
    // 视图列表 删除
    handleDeleteIcon(item, index) {
      console.log("删除", item, "index", index);
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}stress/view/${item.id}`).then((res) => {
          if (res.status == 200) {
            this.$message.success("删除成功");
            this.getViewsList();
          } else {
            this.$message.error(res.errMsg);
          }
        });
      });
    },
    // 视图列表 编辑之后回车事件
    handleEnterEvent(item) {
      this.$axios.put(`${this.baseURL}stress/view/name/${item.id}?viewName=${this.inputValue}`).then((res) => {
        if (res.status == 200) {
          this.$message.success("修改成功");
          this.getViewsList();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 增加父节点
    handleAddTop() {
      if (!this.diyCurrViewId) {
        this.$message.warning("请选择视图");
        return;
      }
      console.log("自定义弹窗当前选择的视图id", this.diyCurrViewId);
      this.$axios.post(`${this.baseURL}stress/add/tree/node/${this.diyCurrViewId}`).then((res) => {
        console.log("增加父节点", res);
        if (res.status == 200) {
          this.getDiyTree(this.diyCurrViewId);
        }
      });
    },
    // 升级
    handleUpgrade() {
      if (!this.leftData.length) {
        return;
      }
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择视图节点");
        return;
      }
      // 如果节点是父节点，不需要升级
      if (this.diyLeftCurrentSelect.treeParentId == 0) {
        this.$message.warning("该节点已是最高级别，不需要升级");
        return;
      }
      console.log("节点升级传参", this.diyCurrViewId, this.diyLeftCurrentSelect.id);
      this.$axios.post(`${this.baseURL}stress/tree/upgrade/${this.diyCurrViewId}/${this.diyLeftCurrentSelect.id}`).then((res) => {
        console.log("节点升级", res);
        if (res.status == 200) {
          this.$message.success("节点升级成功");
          this.getDiyTree(this.diyCurrViewId);
        }
      });
    },
    // 降级
    handleDownGrade() {
      if (!this.leftData.length) {
        return;
      }
      if (!this.diyLeftCurrentSelect.id) {
        this.$message.warning("请选择视图节点");
        return;
      }
      // 如果节点是叶子节点，不需要降级
      if (this.diyLeftCurrentSelect.treeParentId > 0 && !this.diyLeftCurrentSelect.children) {
        this.$message.warning("该节点已是最低级别，不需要降级");
        return;
      }
      this.$axios.post(`${this.baseURL}stress/tree/demotion/${this.diyCurrViewId}/${this.diyLeftCurrentSelect.id}`).then((res) => {
        console.log("节点降级", res);
        if (res.status == 200) {
          this.$message.success("节点降级成功");
          this.getDiyTree(this.diyCurrViewId);
        }
      });
    },
    // 自定义 左树点击
    handleLeftNodeClick(node, data) {
      // node.flag = true
      // console.log('当前是否处于编辑状态------------------------------', this.isHandleEdit)
      console.log("当前点击的树节点--------------------------------", node);
      // console.log('上一个处于编辑中的节点----------------------------', this.currentEditNode)
      if (this.currentEditNode.id != node.id) {
        this.currentEditNode.editFlag = false;
      }
      // console.log('当前点击的树节点存的数组11111111111111111111111----------------------------------------', this.dblClickNodeArr)
      // 只保留当前点击节点处于可增改删状态
      if (this.dblClickNodeArr.length > 0) {
        this.dblClickNodeArr.forEach((item) => {
          if (item.id != node.id) {
            this.dblClickNodeArr.push(node);
          }
          this.dblClickNodeArr[0].flag = false;
          if (this.dblClickNodeArr.length > 1) {
            this.dblClickNodeArr.splice(0, 1);
          }
        });
      } else {
        this.dblClickNodeArr.push(node);
      }
      // console.log('当前点击的树节点存的数组2222222222222222222----------------------------------------', this.dblClickNodeArr)
      if (this.dblClickNodeArr.length == 1 && this.dblClickNodeArr[0].id == node.id && !node.editFlag) {
        // this.dblClickNodeArr[0].flag = true
        node.flag = true;
      }
    },
    // 自定义 左树checkbox
    handleLeftCheckChange(data) {
      console.log("-------------视图结构树选中的节点", data);
      this.diyLeftCurrentSelect = data;
    },
    // 视图树 新增子节点
    handleViewTreeAdd(node, data) {
      // console.log('node', node)
      // console.log('data', data)
      this.$axios.post(`${this.baseURL}stress/add/tree/child/node/${data.viewId}/${data.id}`).then((res) => {
        console.log("视图结构树新增子节点", res);
        if (res.status == 200) {
          this.$message.success("新增子节点成功");
          this.getDiyTree(data.viewId);
          this.dblClickNodeArr = [];
        }
      });
    },
    // 视图树 编辑节点
    handleViewTreeEdit(node, data) {
      this.isHandleEdit = true;
      data.editFlag = true;
      data.flag = false;
      this.editInputValue = data.treeName;
      this.currentEditNode = data;
    },
    // 编辑 输入框内容
    handleInputEvent(value, node, data) {
      console.log("修改树节点", value);
      this.editInputValue = value;
    },
    // 视图树 编辑节点内内容 回车提交
    handleViewEditSubmit(node, data) {
      // console.log('发送给后端的名称-------', this.editInputValue)
      let str = this.editInputValue.replace(/\#/g, "%23");
      console.log("发送给后端的名称-------", str);
      this.$axios.put(`${this.baseURL}stress/update/view/tree/${data.id}?treeName=${str}`).then((res) => {
        console.log("视图结构树编辑节点名称", res);
        if (res.status == 200) {
          this.$message.success("修改成功");
          this.getDiyTree(data.viewId);
          this.currentEditNode = {};
          this.dblClickNodeArr = [];
        }
      });
    },
    // 视图树 删除节点
    handleViewTreeDelete(node, data) {
      console.log("点击视图结构树删除");
      // let postData = {
      //     ids: data.id,
      //     viewId: data.viewId
      // }
      this.$axios.delete(`${this.baseURL}stress/delete/view/tree/${data.viewId}/${data.id}`).then((res) => {
        console.log("视图结构树删除节点", res);
        if (res.status == 200) {
          this.$message.success("删除成功");
          this.getDiyTree(this.diyCurrViewId);
        }
      });
    },
    // 从左边移动到组
    handleBtnFromLeft() {
      let leftChecked = this.$refs.leftTree.getCheckedKeys();
      if (!leftChecked.length) {
        this.$message.warning("请选择视图列表数据");
        return;
      }
      let data = {
        ids: leftChecked,
        viewId: this.diyCurrViewId
      };
      console.log(data);
      this.$axios.post(`${this.baseURL}stress/untie`, data).then((res) => {
        if (res.status == "200") {
          this.$message.success("设置成功");
          this.getDiyTree(this.diyCurrViewId);
          this.getDefaultTree(this.diyCurrViewId);
        }
      });
    },
    // 从右边移动到组
    handleBtnFromRight() {
      let rightChecked = this.$refs.rightTree.getCheckedNodes();
      let leftChecked = this.$refs.leftTree.getCheckedKeys();
      if (!rightChecked.length) {
        this.$message.warning("请选择测点数据");
        return;
      }
      if (!leftChecked.length) {
        this.$message.warning("请选择视图列表数据");
        return;
      }
      let data = {
        id: this.diyLeftCurrentSelect.id,
        list: rightChecked,
        viewId: this.diyCurrViewId
      };
      this.$axios.post(`${this.baseURL}stress/relevance`, data).then((res) => {
        if (res.status == "200") {
          this.$message.success("设置成功");
          this.rightKeywords = "";
          this.getDiyTree(this.diyCurrViewId);
          this.getDefaultTree(this.diyCurrViewId);
        }
      });
    },
    // 自定义 右侧树 实时搜索关键字
    handleRightKeyWordsInput() {
      if (!this.diyCurrViewId) {
        this.$message.warning("请选择视图");
        return;
      }
      this.getDefaultTree(this.diyCurrViewId);
    },
    // 自定义 右侧树 过滤关键字
    filterRightNode(value, data) {
      if (!value) return true;
      return data.targetName.indexOf(value) !== -1;
    },
    // 自定义 右侧树 模糊查询
    handleRightFilter() {
      if (!this.diyCurrViewId) {
        this.$message.warning("请选择视图");
        return;
      }
      this.getDefaultTree(this.diyCurrViewId);
    }
  },
  components: {
    LeftLinkRight,
    Charts,
    TableList,
    ComparePoint
  }
};
</script>

<style scoped lang="scss">
::v-deep .my-dialog div.el-dialog {
  width: 55% !important;
  height: 80%;
  border: none;
  background: linear-gradient(0deg, #0a173d 2%, #0d3772);
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 51px);
    padding: 0 50px 50px 50px;
  }
}
::v-deep .el-dialog__header {
  border: none !important;
}
.stress {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  .left-tree {
    width: 90%;
    height: 100%;
    padding: 30px 20px;
    border: 1px solid;
    border-image: linear-gradient(300deg, rgba(31, 87, 188, 1), rgba(28, 73, 165, 1)) 1 1;
    .left-ul {
      width: 100%;
      display: flex;
      margin-top: 20px;
      border-bottom: 1px solid rgba(69, 202, 255, 0.21);
      .ul-div {
        display: flex;
        width: 50%;
      }
      li {
        width: 100%;
        font-size: 14px;
        text-align: center;
        padding-bottom: 6px;
        span {
          width: 90%;
          display: inline-block;
          i.add {
            font-size: 12px;
            color: #2772f0;
          }
          i.reset {
            font-size: 16px;
            font-weight: 600;
            color: #2772f0;
          }
        }
      }
      li:hover {
        cursor: pointer;
      }
      .active-li {
        border-bottom: 3px solid #2772f0;
      }
      .my-divider {
        top: 5px;
        background: #0b3a6d;
      }
    }
    .tree-wrapper {
      height: calc(100% - 80px);
      .my-tree {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: none;
        margin-top: 10px;
        color: #fff;
      }
    }
  }
  .right-info {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding: 30px;
    border: 1px solid;
    border-image: linear-gradient(300deg, rgba(31, 87, 188, 1), rgba(28, 73, 165, 1)) 1 1;
    .top-wrapper {
      height: 62px;
      // line-height: 32px;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      // margin-left: 21px;
      .point-name {
        font-size: 14px;
        margin-top: 3px;
        img {
          display: inline-block;
          margin-right: 15px;
        }
        // span {
        //   margin-right: 24px;
        // }
        .marl30 {
          margin-left: 30px;
        }

        .middle-span {
          margin: 0 10px;
        }
      }
      .point-ul {
        // display: flex;
        width: 90%;
        li {
          // width: 300px;
          display: inline-block;
          font-size: 14px;
          margin-right: 20px;
          margin-bottom: 10px;
          // margin-left: 15px;

          img {
            display: inline-block;
            margin-right: 10px;
          }
          .middle-span {
            margin: 0 10px;
          }
        }
      }
      .btn-right {
        position: absolute;
        right: 0;
        /* .compare-btn {
                    margin-right: 15px;
                } */
      }
    }
    .time-wrapper {
      display: flex;
      .time-btn-group {
        margin-right: 15%;
        ::v-deep button.el-button {
          padding: 6px 20px;
        }
      }
      .time-range-picker {
        .select-css {
          height: 32px;
          line-height: 32px;
          ::v-deep input {
            border: 1px solid #2667db;
          }
        }
      }
    }
    .chart-wrapper {
      min-height: 300px;
    }
  }
}
.add-wrapper {
  display: flex;
  .add-name {
    width: 45%;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    span {
      margin-right: 10px;
    }
    ::v-deep .el-input {
      width: 80%;
      input {
        border: 1px solid #2260cb;
        border-radius: 4px;
      }
    }
  }
  .list-wrapper {
    flex: 1;
    position: relative;
    margin-left: 6px;
    .select-ul {
      position: absolute;
      width: 200px;
      height: 120px;
      overflow: auto;
      padding-right: 5px;
      background: #102c63;
      font-size: 14px;
      font-weight: 400px;
      color: #8b919e;
      line-height: 2;
      border-radius: 8px;
      box-shadow: 0px 2px 9px 0px rgba(2, 25, 29, 0.67);
      li {
        display: flex;
        padding-left: 15px;
        .icon {
          width: 85px;
          height: 100%;
          i {
            margin-right: 5px;
            color: #2772f0;
          }
        }
        .name {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .my-edit-input {
            width: 100%;
          }
        }
      }
      li:hover {
        cursor: pointer;
        background: rgba(50, 116, 255, 0.24);
        color: #fff;
      }
    }
    img:hover {
      cursor: pointer;
    }
  }
}
.btn-wrapper {
  display: flex;
  margin: 10px 0;
  img:nth-child(2) {
    margin: 0 20px;
  }
  img:hover {
    cursor: pointer;
  }
}
.tree-wrapper {
  height: calc(100% - 80px);
  display: flex;
  .dialog-left-tree {
    width: 45%;
    padding: 10px 20px;
    .my-left-tree {
      height: calc(100% - 50px);
      overflow-y: auto;
      background: none;
      margin-top: 10px;
      color: #fff;
    }
  }
  .dialog-right-tree {
    width: calc(55% - 240px);
    padding: 10px 20px;
    .my-right-tree {
      height: calc(100% - 50px);
      overflow-y: auto;
      background: none;
      margin-top: 10px;
      color: #fff;
    }
  }
  .dialog-left-tree,
  .dialog-right-tree {
    background: linear-gradient(180deg, rgba(0, 35, 89, 0.67) 1%, rgba(19, 18, 68, 0.65));
    border: 1px solid #2667db;
  }
  .dialog-btn {
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .dialog-btn-group {
      text-align: center;
      line-height: 5;
      .el-button {
        width: 160px;
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
.custom-tree-node {
  width: 100%;
  display: flex;
  .node-icon {
    width: 100px;
    color: #2772f0;
    /* font-size: 16px; */
    i:nth-child(2) {
      margin: 0 10px;
    }
  }
  .node-name {
    flex: 1;
  }
}
::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: rgba(50, 116, 255, 0.24) !important;
}
::v-deep .el-tree-node__content {
  height: 34px;
}
</style>
