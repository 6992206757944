<template>
  <!-- 单桥首页的数据查看 -->
  <div class="dataShow box box_column winStyle">
    <div class="close pointer" @click="close">
      <img src="../../../assets/images/all/guanbi_icon.png" alt="" />
    </div>
    <!-- <div class="top box justify center noFlex">
      <div>数据查看</div>
      <div class="pointer" @click="large()">
        <img src="../../../assets/images/img/set/shouqi_icon.png" alt="" />
      </div>
    </div> -->
    <div class="flex1 bottom box box_column noFlex no-bottom">
      <div class="box center justify">
        <div>
          <div class="box center">
            <div class="mR20" v-for="(item, index) in targetList" :key="index">{{ item.code }}</div>
          </div>
        </div>

        <!-- <div class="box end center pointer" style="margin-top: 15px; margin-bottom: 10px" @click="win2Title">
          <div class="mR10 fs14" style="color: #2772f0">{{ titleName }}</div>
          <div class="">
            <img src="../../../assets/images/img/set/Combined Shape.png" alt="" style="transform: rotate(180deg)"
              v-if="win22 == 1" />
            <img src="../../../assets/images/img/set/Combined Shape.png" alt="" v-if="win22 == 0" />
          </div>
        </div> -->
      </div>
      <div class="box justify mT14">
        <div class="new_ui_select"></div>
      </div>
      <div class="newUi box justify" style="margin-bottom: 15px">
        <div class="box">
          <!-- 时间切换 -->
          <ul class="box center">
            <li style="margin-right: 8px" class="dayColorBg pointer" v-for="(item, index) in dayList" :key="index" :class="dayActive == item.id ? 'dayColor' : ''" @click="changeDay(item.id)">
              {{ item.name }}
            </li>
          </ul>
          <div class="timeUi" style="margin-right: 8px">
            <div class="icon1 pointer" @click="add">
              <img src="../../../assets/images/alone/left.png" alt="" />
            </div>
            <div class="icon2 pointer" @click="reduce">
              <img src="../../../assets/images/alone/right.png" alt="" />
            </div>
            <el-date-picker @change="selectDate" :clearable="false" v-model="time" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy.MM.dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </div>
          <!-- 均值和原始值切换 -->
          <!-- <ul class="box center">
            <li style="margin-right: 8px" class="dayColorBg pointer" v-for="(item, index) in timeList" :key="index"
              :class="timeActive == item.id ? 'dayColor' : ''" @click="changeTime(item.id)">
              {{ item.name }}
            </li>
          </ul> -->
        </div>
        <div class="tab_new pointer" :class="{ tabColor: tabActive == 2, disable_icon: !myOption }" @click="tab('2')">数据导出</div>
      </div>
      <!-- 图表 -->
      <div class="flex1 tu">
        <div class="box center" style="width: 100%; height: 100%">
          <div class="unitDiv" v-show="!isShowNoData">
            <div class="d1">单位：{{ units }}</div>
          </div>
          <div class="flex1" style="width: 100%; height: 100%">
            <div class="box flexTop" v-show="timeActive > 0">
              <div>
                <div class="box" style="margin-bottom: 7px">
                  <div class="box flexBottom mR24">
                    <div class="fs12 width50">最大值:</div>
                    <div class="fs16 width60">{{ arrList.max }}</div>
                  </div>
                  <div class="box flexBottom mR24">
                    <div class="fs12 width50">平均值:</div>
                    <div class="fs16 width60">{{ arrList.avg }}</div>
                  </div>
                  <div class="box flexBottom">
                    <div class="fs12 width50">方差:</div>
                    <div class="fs16 width60">{{ arrList.diff }}</div>
                  </div>
                </div>
                <div class="box">
                  <div class="box flexBottom mR24">
                    <div class="fs12 width50">最小值:</div>
                    <div class="fs16 width60">{{ arrList.min }}</div>
                  </div>
                  <div class="box flexBottom mR24">
                    <div class="fs12 width50">标准差:</div>
                    <div class="fs16 width60">{{ arrList.number }}</div>
                  </div>
                  <div class="box flexBottom">
                    <div class="fs12 width50">极差:</div>
                    <div class="fs16 width60">{{ arrList.range }}</div>
                  </div>
                </div>
              </div>
              <!-- <div class="mL60">{{name}}-过程线图</div> -->
            </div>
            <div v-if="length > 0" style="height: 5px"></div>
            <div v-show="!isShowNoData" style="height: 435px">
              <div id="myecharts" class="flex1 echarts"></div>
              <div class="stress-img">
                <measure-img :imgData="imgData" :img="imgData.img" />
              </div>
            </div>

            <div class="no-data-div" v-show="isShowNoData">
              <img src="../../../assets/images/noData.png" alt="" />
            </div>
          </div>
        </div>
        <div class="tuBg"></div>
      </div>
    </div>
    <div class="bottom box box_column noFlex" v-show="win2 == 0"></div>

    <!-- 表格 -->
    <table-list :sendData="sendData"></table-list>
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";
import echarts from "echarts";
import TableList from "./TableList";
import measureImg from "@/components/measureImg";
export default {
  name: "dataView",
  props: ["closeShow", "targetId", "getData", "units"],
  data() {
    return {
      projectId: "",
      titleName: "展开查看全部功能", //  展开查看全部功能
      win2: "1", //整体隐藏
      win22: "0", //部分隐藏
      //  二级树形
      options1: [],
      value: "", //测点
      unit: "", //单位
      // targetId: "", //选中id
      name: "", //测点名称

      tabActive: "", //tab切换

      dayActive: "3", //日期选择  默认选择近10日
      dayList: [
        { id: "1", name: "当天" },
        { id: "2", name: "本周" },
        { id: "3", name: "近10天" },
        { id: "4", name: "本月" }
      ],
      timeList: [
        { id: "0", name: "原始值(近1小时)" },
        { id: "1", name: "1分钟均值" },
        { id: "2", name: "10分钟均值" },
        { id: "3", name: "1小时均值" },
        { id: "4", name: "日均值" }
      ],
      timeActive: 0, //默认选择原始值
      typeActive: "1",
      typeList: [
        { id: "1", name: "趋势分析" },
        { id: "2", name: "趋势分析" },
        { id: "3", name: "趋势分析" }
      ],
      time: "",
      time1: "", //存放时间戳
      time2: "",
      size: "50000", //图表最多显示多少条

      // 默认选中值
      selected: "A",
      // 数据默认字段
      defaultProps: {
        parent: "parentId", // 父级唯一标识
        value: "id", // 唯一标识
        label: "label", // 标签显示
        children: "children" // 子级
      },
      arrList: {}, //计算后的6个值

      arrData: [], //曲线总条数
      time: [],

      isShowNoData: false,

      length: "0",
      sendData: {},

      color: ["#2772F0", "#80C0FF", "#F7B500", "#FFBA4C", "#9091AE", "#3180FF", "#b66dbb", "#c9d0f7", "#fa5757", "#03f5f7", "#ffae00", "#c9d0f7", "#ff5707"],
      imgData: { img: "", list: [] },
      targetList: [] //三视图传过来的测点数组。
    };
  },
  //创建
  created() {
    console.log("chart-created");
    this.projectId = sessionStorage.getItem("projectId");
    this.sendData.targetId = this.targetId;
    this.targetArr(this.getData);
    this.getImgData(this.targetId);
    // this.targetArr(this.getDate)
  },
  watch: {
    targetId(newValue, oldValue) {
      console.log("接收到的targetId", newValue);
      this.sendData.targetId = this.targetId;
      this.getImgData(newValue);
    },
    getData(newValue, oldValue) {
      console.log("接收到的getData", newValue);
      this.arrList = {};
      this.targetArr(newValue);
    }
  },

  //安装
  mounted() {
    var day = new Date();
    var day1 = day.getTime(); //获得当前时间戳
    var day2 = day1 - 10 * 24 * 60 * 60 * 1000;

    this.time2 = day.getTime(); //当前时间戳
    this.time1 = day1 - 10 * 24 * 60 * 60 * 1000; //中国标准时间

    var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
    var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
    console.log(start, end);
    this.$set(this.sendData, "startTime", start);
    this.$set(this.sendData, "endTime", end);
    // this.sendData.startTime = start
    // this.sendData.endTime = end
    this.time = [];
    this.time.push(start);
    this.time.push(end);

    // this.changeTime(3);
    // this.timeActive = 3;
    // this.treeData();
  },
  components: {
    TableList,
    measureImg
  },
  //方法
  methods: {
    getImg(id) {
      return `${this.baseURL}sys/sysImages/img/${id}`;
    },
    getImgData(id) {
      this.$axios
        .get(`${this.baseURL}stress/target/info/${id}`)
        .then((res) => {
          console.log("图数据", res);
          if (res.data && res.data.errCode == "200") {
            let data = res.data.data || {};

            this.imgData["img"] = "";
            this.imgData["list"] = [];
            if (data.imageId) {
              this.imgData["img"] = this.getImg(data.imageId);

              let reg = {
                back: data.back,
                backName: data.backName,
                front: data.front,
                frontName: data.frontName,
                profileName: data.profileName
              };
              this.imgData["list"].push(reg);
            }

            this.$emit("getImgData", data);
          }
        })
        .catch((err) => {});
    },
    selectDate() {
      console.log("selectDate", this.time);
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },
    // 接收三视图传过来的值
    targetArr(e) {
      this.warningData = [];
      if (e.length == 0) {
        // this.isShowNoData = true
        // this.$chart1.moreLines("myecharts", [], [], [], [], "", "", true);
        return;
      }
      //  lengh =1 图标就一条。
      var data = {
        target: this.targetId,
        timeActive: this.timeActive,
        startTime: this.time[0],
        endTime: this.time[1]
      };
      console.log("data-e", data);
      this.sendData = data;
      if (this.timeActive == 0) {
        // this.$bus.$emit("getTableStress", data); //单条的时候查询列表
        // this.data1(); //进入原始值
        this.getWarningData(2);
      } else {
        // this.$bus.$emit("getTableStress", data); //单条的时候查询列表
        // this.data(); //进入均值
        this.getWarningData(1);
      }
    },

    dong() {
      if (this.closeShow == 1) {
        this.closeShow = 0;
        setTimeout(() => {
          this.closeShow = 1;
        }, 10);
      }
      // let myChart = this.$echarts.init(document.getElementById('echarts'));
      // myChart.setOption(option)
      // window.addEventListener("resize", () => { myChart.resize();});

      // let erd = elementResizeDetectorMaker();
      // let that = this;
      // erd.listenTo(document.getElementById("echarts"), (element) => {
      //     let width = element.offsetWidth;
      //     let height = element.offsetHeight;
      //     console.log(width, height)
      //     that.$nextTick(() => {
      //         //使echarts尺寸重置
      //     that.$echarts.init(document.getElementById("echarts")).resize();
      //     });
      // });
    },

    // 关闭窗口
    close() {
      this.closeShow = 0;
      this.$emit("getAdd", "0"); // 给父组件的
      var Dom = document.getElementById("largeWin");
      Dom.style.width = "1326px";
      Dom.style.marginLeft = "-663px";
      Dom.style.left = "50%";
      this.timeActive = "2";
      this.tabActive = ""; //
    },
    // 显示隐藏主体
    large() {
      if (this.win2 == "0") {
        this.win2 = "1";
      } else {
        this.win2 = "0";
      }
      //  var Dom  = document.getElementById('largeWin');
      //  if( Dom.style.width == "100%"){
      //      this.$bus.$emit('largeWin','2');      //缩小
      //  }else{
      //      this.$bus.$emit('largeWin','1');    //缩小
      //  }
    },
    win2Title() {
      if (this.win22 == 1) {
        this.titleName = "展开查看全部功能";
        this.win22 = 0;
      } else {
        this.titleName = "收起查看全部功能";
        this.win22 = 1;
      }
    },

    // 时间左
    add() {
      this.time1 = this.time1 - 24 * 60 * 60 * 1000;
      var start = moment(this.time1).format("YYYY-MM-DD HH:mm:ss");
      this.$forceUpdate();
      this.time[0] = start;
      this.time = [this.time[0], this.time[1]];
      console.log("time", this.time);
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },
    // 时间右
    reduce() {
      this.time2 = this.time2 + 24 * 60 * 60 * 1000;
      var end = moment(this.time2).format("YYYY-MM-DD HH:mm:ss");
      this.$forceUpdate();
      this.time[1] = end;
      this.time = [this.time[0], this.time[1]];
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },

    // 时间切换
    changeDay(id) {
      console.log("快捷选天", id);
      var day = new Date();

      var time2 = day.getTime(); //当前时间戳
      this.time2 = time2; //保存当前时间时间戳
      time2 = moment(time2).format("YYYY-MM-DD HH:mm:ss");
      console.log("time2", time2);

      // 获取当天
      if (id == 1) {
        var time1 = new Date(new Date().toLocaleDateString()).getTime(); //当天时间戳
        this.time1 = time1; //保存开始处时间错
        time1 = moment(time1).format("YYYY-MM-DD HH:mm:ss");
        console.log("time1", time1);
        this.time = [time1, time2];
      }
      // 本周
      if (id == 2) {
        var nowTemp = new Date(); //当前时间
        var oneDayLong = 246060 * 1000; //一天的毫秒数
        var c_time = nowTemp.getTime(); //当前时间的毫秒时间
        var c_day = nowTemp.getDay() || 7; //当前时间的星期几
        var m_time = c_time - (c_day - 1) * oneDayLong; //当前周一的毫秒时间
        var monday = new Date(m_time); //设置周一时间对象
        var m_year = monday.getFullYear();
        var m_month = monday.getMonth() + 1;
        var m_date = monday.getDate();
        var time = m_year + "-" + m_month + "-" + m_date + " " + "00:00:00"; //周一的年月日
        console.log("time", time);
        this.time = [time, time2];
        this.time1 = monday; //获取周一0点时间戳
      }
      // 近十天
      if (id == 3) {
        var day = new Date();
        var day1 = day.getTime(); //获得当前时间戳
        var day2 = day1 - 10 * 24 * 60 * 60 * 1000;
        this.time1 = day2;
        // this.time1 = day.getTime();                   //当前时间戳
        // this.time2 = day1 - 10*24 * 60 * 60 * 1000;  //中国标准时间
        var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
        var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
        this.time = [start, end];
      }
      // 本月
      if (id == 4) {
        var data = new Date();
        data.setDate(1);
        data.setHours(0);
        data.setSeconds(0);
        data.setMinutes(0);
        var time = this.formatDateTime(data.getTime());
        this.time = [time, time2];
      }

      this.dayActive = id;

      // this.data();    //获取均值数据
      this.selectData(); //判断是原始值，还是均值
    },
    formatDateTime(inputTime) {
      var date = new Date(inputTime);
      this.time1 = date;
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    // 数据列表/数据导出
    tab(val) {
      if (!this.myOption) {
        this.$message.error("您没有查看权限！");
        return;
      }

      this.tabActive = val;
      if (val == 1) {
        this.getTableData();
      }
      if (val == 2) {
        this.dataExport(); //数据导出
      }
    },

    // 下拉选择设备
    changeTarget(item) {
      console.log("item", item);

      this.value = item;
      this.unit = item.unit;
      // this.name = item.name;

      this.selectData(); //切换历史数据与均值
    },
    // 选择类型
    changeType() {},

    // 表格
    handleSelectChange(val) {
      console.log(val);
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    // -------------------------数据请求-----------------------------------
    // 测点树形
    treeData() {
      this.$axios
        .get(`${this.baseURL}pandect/select/targertypegroup/list/two?bid=${this.projectId}`)
        .then((res) => {
          console.log("树形数据", res);
          this.options1 = res.data.data;
          this.value = this.options1[0].list[0];
          // this.unit = this.options1[0].list[0].unit;
          // this.name = this.options1[0].list[0].name;
          this.targetId = this.options1[0].list[0].id;
          // this.selectData();  //切换历史数据与均值

          //  var data = {
          //     'target': this.targetId,
          //     'timeActive': this.timeActive,
          //     'startTime': this.time[0],
          //     'endTime': this.time[1]
          // };
          // this.$bus.$emit('getTable', data)
        })
        .catch((err) => {});
    },
    // 数据导出
    dataExport() {
      console.log("------------------------当前时间类型", this.timeActive);
      // var targetId = this.targetId; // 传感器id
      // console.log('time', this.time,this.time[0], this.time[1]);
      // return;
      let data = {
        targetId: this.targetId,
        startTime: this.time[0],
        endTime: this.time[1],
        // type: this.timeActive,
        sort: 1,
        size: this.size
      };

      if (this.timeActive == 0) {
        let path = `pandect/original/excel/${data.targetId}?sort=1&size=${data.size}&startTime=${data.startTime}&endTime=${data.endTime}`;
        this.$axios
          .get(`${this.baseURL}${path}`, { responseType: "blob" })
          .then((res) => {
            console.log("数据导出历史数据", res);
            let a = document.createElement("a");
            let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
            let objectUrl = URL.createObjectURL(blob);
            a.setAttribute("href", objectUrl);
            a.setAttribute("download", "数据导出.xlsx");
            a.click();
          })
          .catch((err) => {});
      } else {
        data.type = this.timeActive;
        this.$axios
          .post(`${this.baseURL}pandect/select/target/data/excel`, qs.stringify(data), { responseType: "blob" })
          .then((res) => {
            console.log("数据导出均值", res);
            let a = document.createElement("a");
            let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
            let objectUrl = URL.createObjectURL(blob);
            a.setAttribute("href", objectUrl);
            a.setAttribute("download", "数据导出.xlsx");
            a.click();
          })
          .catch((err) => {});
      }
    },
    pushArr(name, line, data, color) {
      console.log("pushArr");
      return {
        silent: true,
        lineStyle: {
          type: line,
          color: color,
          width: 1
        },
        label: {
          formatter: name
        },
        yAxis: data
      };
    },
    getWarningData(flag = 1) {
      this.warningData = [];
      this.$axios
        .post(`${this.baseURL}pandect/quota/line/${this.targetId}`)
        .then((res) => {
          console.log("预警标线", res, "flag", flag);

          let obj = res.data.data;
          let colorarr = ["rgba(218, 78, 78, 1)", "rgba(247, 142, 46, 0.4)", "rgba(59, 120, 195, 1)", "rgba(131, 187, 255, 0.6)"];
          let typeArr = ["solid", "solid", "solid", "solid"];
          let objarr = [];
          // "targetId": 219,
          // "duration": 1,
          // "alower": 90,
          // "blower": 80,
          // "clower": 9,
          // "dupper": 1,
          // "eupper": 2,
          // "fupper": 5,
          // "levelFirst": "1",
          // "levelSecond": "1",
          // "levelThird": ""
          if (obj["levelFirst"] == "1") {
            objarr.push(this.pushArr("重度预警", "solid", obj["alower"], colorarr[0]));
            objarr.push(this.pushArr("重度预警", "solid", obj["dupper"], colorarr[0]));
            console.log("objarr", objarr);
          }
          if (obj["levelSecond"] == "1") {
            objarr.push(this.pushArr("中度预警", "solid", obj["blower"], colorarr[1]));
            objarr.push(this.pushArr("中度预警", "solid", obj["eupper"], colorarr[1]));
            console.log("objarr", objarr);
          }
          if (obj["levelThird"] == "1") {
            objarr.push(this.pushArr("轻度预警", "solid", obj["clower"], colorarr[2]));
            objarr.push(this.pushArr("轻度预警", "solid", obj["fupper"], colorarr[2]));
            console.log("objarr", objarr);
          }

          this.warningData = objarr;
          if (flag == 1) {
            console.log("this.data()");
            this.data();
          } else {
            console.log("this.data11111()");
            this.data1();
          }
          // this.selectData();
        })
        .catch((err) => {});
    },
    //  均值选择 timeId == 选择的id
    changeTime(timeId) {
      this.timeActive = timeId; //均值的时间id
      this.selectData();
    },

    // 切换历史数据与均值
    selectData() {
      var length = this.targetList.length;
      this.length = length;
      var data = {
        target: this.targetId,
        timeActive: this.timeActive,
        startTime: this.time[0],
        endTime: this.time[1]
      };

      this.sendData = data;
      // timeActive =0 是历史数据     length>1 代表多条
      if (this.timeActive == 0) {
        if (length > 1) {
          this.changeLine1(this.targetList); //多条曲线的   --- 历史
        } else {
          this.$bus.$emit("getTableStress", data); //单条的时候查询列表
          this.data1(); //进入原始值
        }
      } else {
        if (length > 1) {
          this.changeLine(this.targetList); //多条曲线 ----- 均线
        } else {
          this.$bus.$emit("getTableStress", data); //单条的时候查询列表
          this.data(); //进入均值
        }
      }
    },

    //  均值数据请求
    data() {
      console.log("time", this.time);
      // var target = this.value;   //设备id
      // var target = this.value.id;   //设备id
      var target = this.targetId; //设备id
      var timeId = this.timeActive;
      // var sort = 0;   //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在读取...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      // });

      var path = `data/history/raw/average/${target}?interval=${timeId}&sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史均值", res);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          var arr = res.data.data;
          this.arrList = {};
          this.arrList.number = arr.number;
          this.arrList.min = arr.min;
          this.arrList.avg = arr.avg;
          this.arrList.max = arr.max;
          this.arrList.range = arr.range;
          this.arrList.diff = arr.diff;

          console.log("arrList99999999", this.arrList, this.warningData);

          var data = res.data.data.data;
          var time = [];
          var max = [];
          var min = [];
          var average = [];
          var standard = [];
          var variance = [];
          var range = [];

          data.forEach((item, index) => {
            time.push(item.time);
            max.push(item.data[0]);
            min.push(item.data[1]);
            average.push(item.data[2]);
            standard.push(item.data[3]);
            variance.push(item.data[4]);
            range.push(item.data[5]);
          });

          var name = ["最大值", "均值", "最小值"];
          //  console.log('unit 649 649 649 649', this.unit);
          document.getElementById("myecharts").removeAttribute("_echarts_instance_");
          this.$chart1.moreLine("myecharts", time, max, min, average, standard, variance, range, name, this.getData[0].unit, false, this.warningData);
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    // 原始值
    data1() {
      // console.log('time', this.time);
      // var target = this.value;   //设备id
      // var target = this.value.id;   //设备id
      var target = this.targetId; //设备id
      var timeId = this.timeActive;
      var sort = 0; //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在读取...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tu")   //loading需要覆盖的DOM节点，默认为body tu tuBg
      // });

      var path = `stress/raw/${target}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}&reverse=1`; // 1 对数组反转
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("原始数据", res, this.warningData);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          if (!res.data.data.length) {
            this.isShowNoData = true;
          } else {
            document.getElementById("myecharts").removeAttribute("_echarts_instance_");
            this.isShowNoData = false;
            this.arrList = {};

            let data = res.data.data;
            let timeX = []; // 时间
            let dataY = []; // 应力
            let dataY1 = []; // 应力变化量

            console.log("data", data);

            data.forEach((item, index) => {
              timeX.push(item.time);
              dataY.push(item.data[0]);
              // dataY1.push(item.variation);
            });

            // var name = ["原始值", "变化量"];
            var name = ["原始值"];

            setTimeout(() => {
              this.$chart1.moreLines("myecharts", timeX, dataY, dataY1, name, this.getData[0].unit, false, this.warningData);
            }, 10);

            // this.loadingPage.close();
          }
          // this.arrList.number = "";
          // this.arrList.min = "";
          // this.arrList.avg = "";
          // this.arrList.max = "";
          // this.arrList.range = "";
          // this.arrList.diff = "";

          // console.log("arrList", this.arrList);
        })
        .catch((err) => {});
    },

    // changeLine   多条曲线 均线
    // changeLine1   多条曲线 历史

    // 多条曲线选择   默认选择的是均线
    changeLine(arr) {
      // console.log('arr是bus传过来的数组');
      this.arrData = []; //清空所有图表
      // var arr = ['187', '216'];
      var list = [];
      arr.forEach((item, index) => {
        // list.push(item.targetId)
        this.changeTimeList(this.timeActive, item.targetId, index, item.code);
      });
      // list是id数组[111,222]
      // list.forEach((item, index)=>{
      //     this.changeTimeList(this.timeActive,  item, index);    //1均值选项， item=id号
      //     // this.$chart1.moreLine1('echarts',this.time,this.arrData,'');
      // })
    },

    //  均值选择 多条
    changeTimeList(selectId, id, numIndex, targetName) {
      this.timeActive = selectId;
      var target = id; //设备id
      // var sort = 0;   //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在上传...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      // });

      var path = `data/history/raw/average/${target}?interval=${selectId}&sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史均值", id, res);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          var arr = res.data.data;
          this.arrList = {};
          this.arrList.number = arr.number;
          this.arrList.min = arr.min;
          this.arrList.avg = arr.avg;
          this.arrList.max = arr.max;
          this.arrList.range = arr.range;
          this.arrList.diff = arr.diff;

          var data = res.data.data.data;
          var time = [];
          var max = [];
          var min = [];
          var average = [];
          var standard = [];
          var variance = [];
          var range = [];

          data.forEach((item, index) => {
            time.push(item.time);
            max.push(item.data[0]);
            min.push(item.data[1]);
            average.push(item.data[2]);
            standard.push(item.data[3]);
            variance.push(item.data[4]);
            range.push(item.data[5]);
          });

          // if(id ==187){
          //     this.time = time;
          // }

          var arrData = [];
          arrData.push(max);
          arrData.push(min);
          arrData.push(average);
          // arrData.push(standard)
          // arrData.push(variance)
          // arrData.push(range)
          var name = ["最大值", "最小值", "均值"];
          var color = ["#2772F0", "#80C0FF", "#F7B500"];

          arrData.forEach((item, index) => {
            var templete = {
              data: item,
              name: targetName + name[index],
              type: "line",
              showSymbol: false, //是否显示原点
              smooth: true, //开启光滑
              itemStyle: {
                normal: {
                  // color: color[numIndex*index],     // 小圆点颜色
                  lineStyle: {
                    width: 3,
                    type: "solid" //'dotted'虚线 'solid'实线
                    // color: color[numIndex*index]     //线条颜色
                  }
                }
              }
            };
            this.arrData.push(templete);
          });
          console.log("arrData", this.arrData);

          // return;
          setTimeout(() => {
            this.$chart1.moreLine1("myecharts", time, this.arrData, this.getData[0].unit);
          }, 500);
          // this.$chart1.moreLine('echarts',time, max, min, average, standard, variance, range,'');
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    // 多条曲线的历史数据查询
    changeLine1(arr) {
      this.arrData = []; //清空所有图表
      // var arr = ['187', '216'];
      var list = [];
      arr.forEach((item, index) => {
        list.push(item.targetId);
      });

      list.forEach((item, index) => {
        this.changeTimeList1(this.timeActive, item, index); //1均值选项， item=id号
        // this.$chart1.moreLine1('echarts',this.time,this.arrData,'');
      });
    },

    //  原始数据  多条
    changeTimeList1(selectId, id, numIndex) {
      this.timeActive = selectId;
      var target = id; //设备id
      // var sort = 0;   //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在上传...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      // });

      var path = `data/history/raw/average/${target}?interval=${selectId}&sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      var path = `data/history/raw/${target}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("原始数据", id, res.data.data);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          var arr = res.data.data;

          var time = [];
          var dataY = [];

          arr.forEach((item, index) => {
            time.push(item.time);
            dataY.push(item.data[0]);
          });

          // if(id ==187){
          //     this.time = time;
          // }

          var arrData = [];
          // arrData.push(time)
          arrData.push(dataY);

          var name = ["历史数据"];
          var color = ["#2772F0", "#80C0FF", "#F7B500"];

          arrData.forEach((item, index) => {
            var templete = {
              data: item,
              name: id + name[index],
              type: "line",
              showSymbol: false, //是否显示原点
              smooth: true, //开启光滑
              itemStyle: {
                normal: {
                  // color: color[numIndex*index],     // 小圆点颜色
                  lineStyle: {
                    width: 3,
                    type: "solid" //'dotted'虚线 'solid'实线
                    // color: color[numIndex*index]     //线条颜色
                  }
                }
              }
            };
            this.arrData.push(templete);
          });
          console.log("arrData", this.arrData);

          // return;
          setTimeout(() => {
            this.$chart1.moreLine1("myecharts", time, this.arrData, this.getData[0].unit);
          }, 500);
          // this.$chart1.moreLine('echarts',time, max, min, average, standard, variance, range,'');
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    targetListData() {
      var arr = ["187", "216"];
      arr.forEach((item, index) => {});
    },

    // 表格
    getTableData() {
      var target = this.targetId;
      // console.log("timeActive", this.timeActive);
      console.log("target", target);
      if (!target) {
        return;
      }

      var path = `data/history/page/raw/average/${target}?interval=${this.timeActive}&sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&current=${this.currentPage}&size=6`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("表格数据", res);
          this.tableData = res.data.data.datas;
          console.log("table", this.tableData);
          this.tableName = {
            targetCode: res.data.data.targetCode,
            param: res.data.data.param[0],
            current: res.data.data.current
          };
          this.total = res.data.data.total;
        })
        .catch((err) => {});
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style scoped lang="scss">
.dataShow {
  width: 100%;
  // height: 100%;
}

// 默认tab切换
.tab_new {
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  width: 96px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #2667db;
}

// 选中tab
.tabColor {
  font-size: 14px;
  width: 96px;
  height: 32px;
  background: #2667db;
  border-radius: 16px;
  border: 1px solid #233361;
}

.dayColorBg {
  // width: 80px;
  padding-left: 22px;
  padding-right: 22px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #7387c6;
  background: #233361;
  border-radius: 15px;
  letter-spacing: 0px;
}

.dayColor {
  color: #cedaff !important;
  background: #0050b4 !important;
}

::v-deep .newUi {
  .el-select {
    width: 118px;
    height: 32px !important;

    .el-input {
      height: 32px !important;

      .el-input__inner {
        border: 1px solid #233361 !important;
        border-radius: 16px !important;
      }

      .el-input__icon {
        line-height: 17px;
      }
    }
  }

  .timeUi {
    position: relative;

    .icon1 {
      position: absolute;
      top: 12px;
      left: 6px;
      z-index: 99;
    }

    .icon2 {
      position: absolute;
      top: 12px;
      right: 6px;
      z-index: 99;
    }

    .el-range-editor.el-input__inner {
      width: 264px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #233361 !important;
      background: #04153f;
      border-radius: 16px !important;
    }

    .el-range-input {
      width: 112px;
      letter-spacing: 0px;
      font-size: 12px !important;
      background: transparent !important;
    }

    // 至
    .el-date-editor .el-range-input,
    .el-date-editor .el-range-separator {
      color: #ffffff;
      height: 30px !important;
    }

    // 头部图标
    .el-date-editor .el-range__icon {
      display: none !important;
    }
  }
}

::v-deep .new_ui_select {
  .el-select {
    width: 200px;
    height: 32px !important;

    .el-input {
      height: 32px !important;

      .el-input__inner {
        border: 1px solid #233361 !important;
        border-radius: 16px !important;
      }

      .el-input__icon {
        line-height: 17px;
      }
    }
  }
}

// 图表样式
.tu {
  width: 100%;
  // height: 100%;
  position: relative;
  padding-top: 17px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 17px;
}
.no-data-div {
  height: 435px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 74px;
    display: inline-block;
  }
}
.stress-img {
  height: 100px;
  margin-top: 15px;
}
.tuBg {
  position: absolute;
  background: rgba($color: #fff, $alpha: 0.05);
  border-radius: 24px;
  width: 100%;
  // height: 340px;
  height: 469px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.legend1 {
  width: 24px;
  height: 3px;
  background: #2772f0;
  border-radius: 2px;
}

.legend2 {
  width: 24px;
  height: 3px;
  background: #f7b500;
  border-radius: 2px;
}

.legend3 {
  width: 24px;
  height: 3px;
  background: #80c0ff;
  border-radius: 2px;
}

#myecharts {
  width: 100%;
  height: 320px;
  z-index: 2;
  overflow: hidden;
}

.close {
  position: absolute;
  right: 0;
  top: -30px;
  right: 0px;
}

.a {
  z-index: 99;
}

.b {
  z-index: -1;
}

.mT14 {
  margin-top: 14px;
}

//单位竖
.unitDiv {
  width: 22px;

  // position: relative;
  .d1 {
    transform: rotate(-90deg) translate(-20%, -50%);
    transform-origin: 0% 0%;
    position: absolute;
    left: 40px;
    // top: 50%;
    // left: 0;
    width: 150px;
    color: #58637f;
  }
}

.width50 {
  width: 50px;
}

.width60 {
  width: 100px;
}

.bottom {
  overflow-y: auto;
  border: 0;
}

.winStyle .no-bottom {
  padding: 0;
  background: none;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
