<template>
  <div class="table-list-page">
    <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData">
      <el-table-column prop="time" label="采集时间"></el-table-column>
      <el-table-column label="测点编号">{{ detailData.targetCode }}</el-table-column>
      <el-table-column label="监测指标">{{ detailData.name }}</el-table-column>
      <!-- <el-table-column prop="data[0]" label="应力(MPa)"></el-table-column> -->
      <!-- <el-table-column label="处理方式">{{ sendData.timeActive | timeFilters }}</el-table-column> -->
      <el-table-column label="最大值" v-if="sendData.timeActive > 0" prop="data[0]"></el-table-column>
      <el-table-column label="最小值" v-if="sendData.timeActive > 0" prop="data[1]"></el-table-column>
      <el-table-column label="平均值" v-if="sendData.timeActive > 0" prop="data[2]"></el-table-column>

      <el-table-column label="原始值" v-if="sendData.timeActive == 0" prop="data[0]"></el-table-column>
    </el-table>
    <common-pagination v-show="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage" :page-size="pageSize" @handleCurrentChange="handleCurrentChange" />
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
export default {
  name: "tableList",
  props: ["sendData"],
  components: {
    CommonPagination
  },
  data() {
    return {
      pageSize: 4, // 之前是6，2022-08-04让改为4
      currentPage: 1,
      total: 0,
      // 表格数据
      tableData: [],
      detailData: {}
    };
  },
  watch: {
    sendData(newValue, oldValue) {
      console.log("表格页面接收到的数据", newValue);
      this.getTableData(newValue);
    }
  },
  mounted() {
    setTimeout(() => {
      this.getTableData(this.sendData);
    }, 100);
  },
  methods: {
    // 获取表格数据
    getTableData(data) {
      let targetId = data.target; // 测点id
      let endTime = data.endTime;
      let sort = 1;
      let startTime = data.startTime;
      let path = "";
      if (!targetId) return;
      // 获取均值还是原始值
      if (data.timeActive == 0) {
        // 原始值
        path = `data/history/page/raw/${targetId}?current=${this.currentPage}&endTime=${endTime}&size=${this.pageSize}&sort=${sort}&startTime=${startTime}&interval=${data.timeActive}`;
      } else {
        // 均值
        path = `data/history/page/raw/average/${targetId}?current=${this.currentPage}&endTime=${endTime}&size=${this.pageSize}&sort=${sort}&startTime=${startTime}&interval=${data.timeActive}`;
      }

      this.$axios.get(`${this.baseURL}${path}`).then((res) => {
        console.log("获取表格数据", res);
        let detail = res.data.data.datas;
        this.tableData = detail;
        this.total = res.data.data.total;
        this.detailData = {
          name: res.data.data.param[0],
          targetCode: res.data.data.targetCode
        };
      });
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    // 翻页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData(this.sendData);
    }
  }
};
</script>
<style scoped lang="scss">
.table-list-page {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
.common-page.pageMain {
  margin-top: 30px;
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
</style>
